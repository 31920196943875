import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmptyTruckList from "./EmptyTruckList/EmptyTruckList";
import Filter from "./EmptyTruckFilter/Filter";
import EmptyTruckSheet from "./EmptyTruckSheet/EmptyTruckSheet";
import Modal from "../Modals/Modal";
import ModalHeader from "../Modals/Header";
import { withRouter } from "react-router-dom";
import Container from "../UI/Container";
import DashboardFooter from "../Dashboard/DashboardFooter";
import ListContainer from "../UI/ListContainer";
import VerificationNotice from "../Modals/AuthGatewayModals/VerificationNotice";
import { getCompany } from "../../store/actions/dashboard/company";

const EmptyTruck = (props) => {
  const dispatch = useDispatch();
  const { loading, error, authFailed } = useSelector(
    (state) => state.dashboard.company
  );
  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [sheetTruck, setSheetTruck] = useState(
    props.location?.state?.truck || null
  );
  const [allTrucks, setAllTrucks] = useState([]);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [showVerificationNotice, setShowVerificationNotice] = useState(false);
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);

  useEffect(() => {
    if (USER && !COMPANY) {
      dispatch(getCompany());
    }
  }, [dispatch]);

  // If user has requested a truck via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    const sheetTruck = props.location?.state?.truck;
    if (routeSheet && allTrucks?.length > 0) {
      if (![...allTrucks, sheetTruck].find((e) => e?._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allTrucks]);

  const handleModalClose = () => {
    setShowRouteModal(false);
    setSheetID(null);
  };

  const handleSheetChange = useCallback((id) => {
    if (!error) {
      // if user is not logged in, allow to view the sheet
      if (!USER && !COMPANY) {
        setSheetID(id);
      }
      // if user is logged in, check if the company is verified
      else if (USER && COMPANY) {
        if (COMPANY.verified) {
          setSheetID(id);
        } else {
          setShowVerificationNotice(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener("scroll", function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener("scroll", scrollEvt);
    }
  }, []);
  return (
    <>
      <Filter fixed={filterFixed} filters={filters} setFilters={setFilters} />
      <ListContainer offset={USER ? 1 : null}>
        <EmptyTruckList
          filters={filters}
          sheetID={sheetID}
          setSheetID={handleSheetChange}
          setAllTrucks={setAllTrucks}
          clearFilters={() => setFilters({})}
          USER={USER}
          COMPANY={COMPANY}
        />
        {allTrucks?.length > 0 && <DashboardFooter />}
      </ListContainer>
      {allTrucks && sheetID && (
        <EmptyTruckSheet
          trucks={[...allTrucks, ...(sheetTruck ? [sheetTruck] : [])]}
          id={sheetID}
          setSheetID={handleSheetChange}
          USER={USER}
          COMPANY={COMPANY}
        />
      )}
      {showRouteModal && (
        <Modal onClose={handleModalClose} small>
          <ModalHeader
            title="Empty truck not found"
            onClose={handleModalClose}
          />
          <h4>The empty truck you requested was not found.</h4>
          <h5>Here are some reasons to why:</h5>
          <ul>
            <li>
              The empty truck you requested was removed or is currently
              inactive.
            </li>
            <li>The link you accessed was broken or missing.</li>
          </ul>
        </Modal>
      )}
      {showVerificationNotice && (
        <VerificationNotice
          onClose={() => setShowVerificationNotice(false)}
          onConfirm={() => setShowVerificationNotice(false)}
        />
      )}
    </>
  );
};

export default withRouter(EmptyTruck);
