import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filter from './Filter/Filter';
import TenderList from './TenderList/TenderList';
import TenderSheet from './Sheet/TenderSheet';
import Modal from '../Modals/Modal';
import ModalHeader from '../Modals/Header';
import Container from '../UI/Container';
import DashboardFooter from '../Dashboard/DashboardFooter';
import ListContainer from '../UI/ListContainer';
import VerificationNotice from "../Modals/AuthGatewayModals/VerificationNotice";
import { getCompany } from "../../store/actions/dashboard/company";

const Tender = (props) => {
  const dispatch = useDispatch();
  const { loading, error, authFailed } = useSelector(
    (state) => state.dashboard.company
  );
  const USER = useSelector((state) => state.dashboard.user?.data);
  const COMPANY = useSelector((state) => state.dashboard.company?.data);
  const [filters, setFilters] = useState({});
  const [filterFixed, setFilterFixed] = useState(false);
  const [sheetID, setSheetID] = useState(
    props.location?.state?.sheet || props.match?.params?.id || null
  );
  const [sheetTender, setSheetTender] = useState(
    props.location?.state?.tender || null
  );
  const [allTenders, setAllTenders] = useState([]);
  const [filteredTenderCount, setFilteredTenderCount] = useState(null);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [showVerificationNotice, setShowVerificationNotice] = useState(false);

  useEffect(() => {
    if (USER && !COMPANY) {
      dispatch(getCompany());
    }
  }, [dispatch]);

  // If user has requested a tender via route, if it doesn't exist, display a modal
  useEffect(() => {
    const routeSheet = props.location?.state?.sheet || props.match?.params?.id;
    const sheetTender = props.location?.state?.tender;
    if (routeSheet && allTenders?.length > 0) {
      if (![...allTenders, sheetTender].find((e) => e?._id === routeSheet)) {
        setShowRouteModal(true);
      }
    }
  }, [props.location, props.match, allTenders]);

  const handleModalClose = () => {
    setShowRouteModal(false);
    setSheetID(null);
  };

  const handleSheetChange = useCallback((id) => {
    if (!error) {
      // if user is not logged in, allow to view the sheet
      if (!USER && !COMPANY) {
        setSheetID(id);
      }
      // if user is logged in, check if the company is verified
      else if (USER && COMPANY) {
        if (COMPANY.verified) {
          setSheetID(id);
        } else {
          setShowVerificationNotice(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= 992) {
      const scrollEvt = document.addEventListener('scroll', function () {
        window.pageYOffset >= 134
          ? setFilterFixed(true)
          : setFilterFixed(false);
      });
      return () => document.removeEventListener('scroll', scrollEvt);
    }
  }, []);

  return (
    <>
      <Filter
        fixed={filterFixed}
        filters={filters}
        setFilters={setFilters}
        tenderCount={filteredTenderCount}
      />
      <ListContainer offset={(USER || COMPANY) ? 1 : null}>
        <TenderList
          filters={filters}
          sheetID={sheetID}
          setSheetID={handleSheetChange}
          setAllTenders={setAllTenders}
          setTenderCount={setFilteredTenderCount}
          clearFilters={() => setFilters({})}
          USER={USER}
          COMPANY={COMPANY}
        />
        {allTenders?.length > 0 && <DashboardFooter />}
      </ListContainer>
      {sheetID && (
        <TenderSheet
          tenders={[...allTenders, ...(sheetTender ? [sheetTender] : [])]}
          id={sheetID}
          setSheetID={handleSheetChange}
          USER={USER}
          COMPANY={COMPANY}
        />
      )}
      {showRouteModal && (
        <Modal onClose={handleModalClose} small>
          <ModalHeader title="Tender not found" onClose={handleModalClose} />
          <h4>The tender you requested was not found.</h4>
          <h5>Here are some reasons to why:</h5>
          <ul>
            <li>
              The tender you requested was removed or is currently inactive.
            </li>
            <li>The link you accessed was broken or missing.</li>
          </ul>
        </Modal>
      )}
      {showVerificationNotice && (
        <VerificationNotice
          onClose={() => setShowVerificationNotice(false)}
          onOpen={() => setShowVerificationNotice(true)}
        />
      )}
    </>
  );
};

export default withRouter(Tender);
